import ApiRequestHandler from "./APIRequestHandler";
import ApiConfig from "./ApiConfig.json"
import ProfileService from "../services/Profile";


let defaultStore = {
    //Build and Envriornment-specific variables
    isProduction: process.env.REACT_APP_ENV?.toString().toUpperCase().match(/^(PROD|PRODUCTION)$/) || !process.env.REACT_APP_ENV,
    buildId: process.env?.REACT_APP_BUILD_ID ?? "NA",
  };

  const setDCPSConfig = () =>{
    if(defaultStore.isProduction){
        return ApiConfig.dcpsProdConfig; //dcps-core
    }
    else{
        return ApiConfig.dcpsDevConfig;
    }
}

export const dcpsConfig = setDCPSConfig();

const profileApiConfig = new ApiRequestHandler(dcpsConfig);
export const profileService = new ProfileService(profileApiConfig);
