import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import { Checkbox, Stack, PrimaryButton, Spinner, SpinnerSize } from '@fluentui/react';
import { profileService } from 'src/utils/Services';
import Select from "react-select";
import NoRecords from './NoRecords';
import { Box, styled, useTheme, useMediaQuery, Typography } from '@mui/material';
import logo from "src/img/brand/dcps_wide.webp";

const Img = styled('img')(({ theme }) => ({}))

const Home = () => {
  const [studentDropdownOptions, setStudentDropDownOption] = useState<any>([]);
  const [formResponses, setFormResponses] = useState<any>([]);
  const [student, setStudent] = useState<any>(null);
  const [studentCount, setStudentCount] = useState<number>(0);
  const [checkBoxesChecked, setCheckBoxesChecked] = useState<boolean>(false);
  const [check1Checked, setCheck1Checked] = useState<boolean>(false);
  const [check2Checked, setCheck2Checked] = useState<boolean>(false);
  const [check3Checked, setCheck3Checked] = useState<boolean>(false);
  const [currentStudent, setCurrentStudent] = useState<any>([]);
  const [loadingStudents, setLoadingStudents] = useState<boolean>(false);
  const [stopSpinner, setStopSpinner] = useState<boolean>(false);
  const [isListEmpty, setIsListEmpty] = useState<boolean>(false);
  const [optionsDisabled, setOptionsDisabled] = useState<boolean>(false);
  const [submitDisabled, setSubmitDisabled] = useState<boolean>(true);
  const [nextDisplay, setNextDisabled] = useState<string>("");
  const [displaySubmit, setDisplaySubmit] = useState<string>("none");
  const [checkBoxDisabled, setCheckBoxDisabled] = useState<boolean>(false);
  const [resetRadio, setResetRadio] = useState<boolean>(false);
  const [answerChecked, setAnswerChecked] = useState<any>([]);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);

  const navigate = useNavigate();

  const setAnswers = (name: string, value: string) => {
    setAnswerChecked((previousAnswers: any) => {
      return {
        ...previousAnswers,
        options: previousAnswers.options.map((option: any) => {
          if (option.Id !== name) {
            return { ...option };
          }
          else {
            return { ...option, Answer: value }
          }
        })
      }
    })
  }

  const resetRadioAnon = (value: boolean) => {
    setResetRadio(value);
  }
  const containerStyles = {
    borderRadius: {
      xs: 0,
      lg: '10px',
    },
    border: {
      xs: "none",
      lg: "1px solid gray",
    },
    backgroundColor: '#f2f2f2',
    padding: {
      xs: '40px',
      lg: '60px',
    },
    boxShadow: {
      xs: "none",
      sm: `
      1px 1px 1.3px hsl(0deg 0% 64% / 0.68),
      4.2px 4.2px 5.6px -1.8px hsl(0deg 0% 64% / 0.54),
      19.6px 19.6px 26.2px -3.7px hsl(0deg 0% 64% / 0.39);
    `},
    fontSize: '18px',
    fontWeight: '300',
    width: {
      xs: "calc(100% - 80px)",
      lg: "clamp(200px, calc(90vw - 40px), 1000px)",
    },
    margin: '20px auto',
    mb: {
      xs: "-20px",
      lg: "80px",
    },
    mt: {
      xs: "-20px",
      lg: "20px"
    }
  }


  const stackTokens = { childrenGap: 10 };

  const questionContainer = {
    display: 'flex',
    width: '100%',
    marginTop: "20px",
  }
  const flexExample = {
    flex: 1,
    fontWeight: '500',
    width: "clamp(200px, 80vw, 800px)",
  }
  const horizontalChoiceGroupStyles = {
    flexContainer: {
      display: 'inline-flex',
      flexDirection: 'row',
      gap: '10px',
    }
  };

  useEffect(() => {

    profileService.getECFForm().then((result: any) => {
      if (result.status === 200) {
        console.log(result.data)
        if (result.data.length !== 0) {
          setFormSubmitted(false);
          setStudent(result.data)
        } else {
          setFormSubmitted(true);
          setStopSpinner(true);
        }
      } else {
        console.log(result);
        //registerError(dispatch, result, "student", true);
      }
    });

  }, [])


  useEffect(() => {
    if (student) {
      checkShowNextStudent(student);
      const submitted = student.every((v: any) => {
        if (v.WasSubmitted) {
          return true;
        } else {
          return false;
        }
      });
      setFormSubmitted(submitted);
      setStudentCount(student.length);
      

      getInitialStudentInfo();
    }
  }, [student]);

  const changeAnsweredCheck = (value) => {
    setAnswerChecked(value);
  }

  const getInitialStudentInfo = () => {
    if (student.length > 0) {
      setCurrentStudent(student[0]);
      setLoadingStudents(true);
      setStopSpinner(true);
      setIsListEmpty(false);
      const newArray = [];
      const checkedValue = {
        options: newArray
      }

      student[0].Questions.forEach((question: any) => {
        const value = {
          Id: question.Id,
          Answer: ''
        }
        checkedValue.options.push(value);
      });

      changeAnsweredCheck(checkedValue);

      student.map((studentItem: any, index: number) => {
        let formIsFilled;
        if (studentItem.Questions[0].Answered === true) {
          formIsFilled = true;
        }
        else {
          formIsFilled = false;
        }
        let item = {
          value: studentItem.Id,
          label: studentItem.Student.Name,
          formFilled: formIsFilled,
          Answers: []
        };
        return studentDropdownOptions.push(item);
      });
    } else {
      setLoadingStudents(false);
      setStopSpinner(true);

      setIsListEmpty(true);
    }
  };

  useEffect(() => {
    if (currentStudent.length <= 0) {
      return;
    } else {
      setRadioDisabled();
    }
  }, [currentStudent]);

  const checkShowNextStudent = (value: Array<any>) => {
    let notSubmittedArray = value.filter((e: any) => e.WasSubmitted === false)
    if (notSubmittedArray.length < 2) {
      setNextDisabled("none");
      setDisplaySubmit("inline");
    }
  }


  const checkEnableSubmit = (value: Array<any>) => {
    if (value.length < 2) {
      setNextDisabled("none");
      setDisplaySubmit("inline");
    }
  }

  const setRadioDisabled = () => {
    if (currentStudent.Questions[0].Answered === true) {
      setOptionsDisabled(true);
      setCheckBoxDisabled(true)
    }
    else {
      setOptionsDisabled(false);
      setCheckBoxDisabled(false);
    }
  }

  const checkChoiceGroupFilled = () => {
    let allQuestionsAnswered = answerChecked.options.every((v: any) => {
      if (v.Answer === '' || undefined) {
        return false;
      } else {
        return true
      }
    })
    if (allQuestionsAnswered) {
      let stu = studentDropdownOptions.find((e: any) => e.value === currentStudent.Id)
      if (stu.formFilled === false) {
        stu.formFilled = true;
        if (formResponses.length !== 0) {
          let answerExists = formResponses.every((v: any) => {
            if (v.Id === stu.value) {
              return true;
            } else {
              return false;
            }
          })
          if (!answerExists) {
            formResponses.push({
              Id: currentStudent.Id,
              Answers: answerChecked.options
            })
          }
        } else {
          formResponses.push({
            Id: currentStudent.Id,
            Answers: answerChecked.options
          })
        }

      }
      console.log(formResponses);
    }
  }

  useEffect(() => {
    if (resetRadio === true) {
      setAnswers('', '');
      resetRadioAnon(false);
    }
  }, [resetRadio]);

  const setCheckBoxStatus = (name: string, value: boolean) => {
    switch (name) {
      case "check1":
        setCheck1Checked(value);
        return;
      case "check2":
        setCheck2Checked(value);
        return;
      case "check3":
        setCheck3Checked(value);
        return;
    }
  }

  useEffect(() => {
    if (check1Checked && check2Checked && check3Checked) {
      setCheckBoxesChecked(true);
    }
    else {
      setCheckBoxesChecked(false);
    }
  }, [check1Checked, check2Checked, check3Checked]);

  useEffect(() => {
    if (checkBoxesChecked) {
      setSubmitDisabled(false);
    }
    else {
      setSubmitDisabled(true);
    }
  }, [checkBoxesChecked, submitDisabled]);

  const onCheckBoxChange = (e: any) => {
    if (e.target.checked) {
      setCheckBoxStatus(e.target.name, true);
    } else {
      setCheckBoxStatus(e.target.name, false)
    }
  }

  const onRadioChange = (e: any) => {
    const { name, value } = e.target;
    setAnswers(name, value);
  };

  const setCStudent = (student: any) => {
    setCurrentStudent(student);
  }

  const onNextStudentClick = (e: any) => {
    checkChoiceGroupFilled();
    let stu = studentDropdownOptions.filter((student: any) => student.formFilled === false);
    checkEnableSubmit(stu);

    let iteration: number = 0;
    let nextStudent = null;

    stu.forEach((e: any, index: number) => {
      if (e.value === currentStudent.Id) {
        iteration = index;
      }
    });

    if (currentStudent.Id === stu[stu.length - 1].value || stu.length === 1) {
      nextStudent = student.filter((student: any) => student.Id === stu[0].value);
    } else {
      nextStudent = student.filter((student: any) => student.Id === stu[iteration + 1].value);
    }
    resetRadioAnon(true);

    setCStudent(nextStudent[0]);

    if (currentStudent.Questions[0].Answered === false) {
      setOptionsDisabled(false)
    }
  }

  const onSelection = (e: any) => {
    if (e.formFilled === false) {
      checkChoiceGroupFilled();
      resetRadioAnon(true);
    }

    let stu = student.filter((student: any) => student.Id === e.value);

    setCStudent(stu[0]);

    checkEnableSubmit(studentDropdownOptions.filter((student: any) => student.formFilled === false));
    if (currentStudent.Questions[0].Answered === false) {
      setOptionsDisabled(false)
    }
  };

  const submitFormData = async (e: any) => {
    e.preventDefault();

    checkChoiceGroupFilled();
    setCheck1Checked(false);
    setCheck2Checked(false);
    setCheck2Checked(false);

    const formObject = formResponses;

    await profileService.postECFForm(formObject).then((result: any) => {
      if (result.status === 200) {
        console.log(result)
        navigate(0);
      } else {
        console.log(result);
        //registerError(dispatch, result, "student", true);
      }
    })
  }

  const theme = useTheme()
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      {formSubmitted && (
        <NoRecords studentCount={studentCount} />
      )}
      {loadingStudents && !formSubmitted && (
        <div>
          <Box sx={{ width: "100%", display: "flex", alignItems: 'center', justifyContent: "center", bgcolor: { xs: "#ffffc9", lg: "unset" } }}>
            <Box sx={(theme) => ({
              display: "grid", gridTemplateColumns: "clamp(100px, 20vw, 300px) 1fr", py: 2, alignItems: "center", justifyContent: "center", px: 4, columnGap: 2,
              [theme.breakpoints.down('sm')]: {
                gridAutoFlow: "row",
                gridTemplateRows: "1fr 1fr",
                gridTemplateColumns: "unset",
              }
            })}>

              <Img src={logo} sx={{ width: { xs: "clamp(100px, 90vw, 300px)", sm: "clamp(100px, 20vw, 300px)" }, justifySelf: { xs: "center", sm: "end" } }} alt="dcps logo" />
              <h1 style={{ textAlign: "center", justifySelf: "start" }}>Parent Emergency Connectivity Fund (ECF) Survey</h1>
            </Box>
          </Box>

          <Box sx={containerStyles}>
            {/* {<>
              <h3>Select Student From DropDown</h3>
              <p>Fill out the questions below for each student. You will not be able to submit until
                questions have been answered for all students. If you do not have more than one student, please disregard.</p>

            </>
            <hr />} */}
            <section style={{ paddingBottom: 20 }}>
              This survey determines the eligibility for receiving a student laptop and/or hotspot with funds from the FCC's Emergency Connectivity
              Fund (ECF). The ECF is a 7.17 billion dollar program to help schools provide their communities with the tools
              and services to participate in remote learning during the COVID-19 emergency period. ECF will help provide relief to millions of students
              and help close the homework gap for students who currently lack necessary internet access or the devices they need to connect to classrooms.
              <br /><br />
              Currently we are out of hotspots, but have plenty of laptops. More funding for hostpsots may come available in the future.
              <br /><br />
              This device is separate from the laptop(s) middle and high-school students are assigned that provide access to their digital textbooks. The devices
              supplied by ECF funding can be used by the student and anyone in their household to assist the student with their academic performance.
              <br /><br />
              Please answer the following survey questions to determine your student's eligibility and priority for receiving an ECF devide (laptop and/or hot spot).
            </section>
            <hr />
            <div>
              <form onSubmit={async (e) => await submitFormData(e)}>
                <Box sx={{ pb: 1 }}>
                  <p><b>Selected Student: </b></p>
                  <Select
                    //className={classes.dropDownSelect}
                    label="Select Student"
                    value={studentDropdownOptions.filter(
                      ({ value }) => value === currentStudent.Id
                    )}
                    options={studentDropdownOptions}
                    onChange={onSelection}
                  />
                </Box>

                {sm ?
                  <>
                    <p>Student ID: <span style={{ fontWeight: 500 }}>{currentStudent.Id}</span></p>
                    <div
                      key="studentInfo"
                      style={{
                        flex: "1 1 50%",
                        paddingLeft: "0px",
                      }}
                    >
                      <p>Student Name: <span style={{ fontWeight: 500 }}>{currentStudent.Student.Name}</span></p>
                      <p>
                        Student Grade Level: <span style={{ fontWeight: 500 }}>{currentStudent.Student.GradeLevel}</span>
                      </p>
                      <p>
                        Student Date of Birth: <span style={{ fontWeight: 500 }}>{currentStudent.Student.DateOfBirth.split('T')[0]}</span>
                      </p>
                    </div>
                  </>
                  :
                  <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr 1fr 1fr", gridTemplateRows: "1fr 1fr", gridAutoFlow: "column", rowGap: 2, border: "1px solid gray", p: 4, m: 2, mx: {xs: 0, md: 2}, bgcolor: "#fff", alignContent: "center", justifyItems: "center" }}>
                    <Typography sx={{ fontSize: 18 }}>Student Name</Typography>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                      {currentStudent.Student.Name}
                    </Typography>

                    <Typography sx={{ fontSize: 18 }}>Student ID</Typography>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                      {currentStudent.Id}
                    </Typography>

                    <Typography sx={{ fontSize: 18 }}>Grade Level</Typography>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                      {currentStudent.Student.GradeLevel}
                    </Typography>

                    <Typography sx={{ fontSize: 18 }}>Date of Birth</Typography>
                    <Typography variant="body2" sx={{fontWeight: 300}}>
                      {currentStudent.Student.DateOfBirth.split('T')[0]}
                    </Typography>
                  </Box>
                }
                <hr style={{ margin: "10px 0 10px" }} />
                {currentStudent.Questions.map((question: any, index: number) => (
                  <React.Fragment key={index}>
                    <div style={questionContainer}>
                      <div style={flexExample}>
                        <span>{question.Text}</span>
                      </div>
                      <label style={{ marginLeft: 20 }}>
                        Yes
                        <input type="radio" disabled={optionsDisabled} value="Yes" key={question.Id} name={question.Id} onChange={onRadioChange} />
                      </label>
                      <label>
                        No
                        <input type="radio" disabled={optionsDisabled} value="No" key={question.Id} name={question.Id} onChange={onRadioChange} />
                      </label>
                      <input type="radio" disabled={optionsDisabled} style={{ display: "none" }} /*checked={resetRadio === true}*/ value="Clear" key={question.Id} name={question.Id} onChange={onRadioChange} />
                    </div>
                    <p style={{ width: "clamp(200px, 80vw, 800px)" }}>{question.SubText}</p>
                  </React.Fragment>

                ))}
                <hr style={{ margin: "20px 0 20px" }} />
                <div>
                  <Stack tokens={stackTokens}>
                    <Checkbox disabled={checkBoxDisabled} name='check1' onChange={onCheckBoxChange} label="I acknowledge that my survey answers are accurate to the best of my 
            knowledge, and I understand the laptop and hotspot procedures."/>
                    <Checkbox disabled={checkBoxDisabled} name='check2' onChange={onCheckBoxChange} label="I acknowledge that I am responsible for the devices assigned to my student and that it is my sole responsibility to monitor my student's activity
            while using the issued devices online."/>
                    <Checkbox disabled={checkBoxDisabled} name='check3' onChange={onCheckBoxChange} label="I understand that I must return all issued devices to the district if my student withdraws from DCPS." />
                  </Stack>
                </div>
                <br />
                <div style={{ textAlign: "center" }}>
                  <PrimaryButton
                    type="submit"
                    text={"Submit"}
                    style={{ display: displaySubmit }}
                    disabled={submitDisabled}
                  />
                </div>
              </form>
              <div style={{ textAlign: "center" }}>
                <br />
                <PrimaryButton
                  type="button"
                  text={"Next Student"}
                  onClick={onNextStudentClick}
                  style={{ display: nextDisplay }}
                //disabled={submitDisabled}
                />
              </div>

            </div>
          </Box>
        </div>
      )}
      {!stopSpinner && (
        <Spinner size={SpinnerSize.large} label="Loading..." />
      )}
      {isListEmpty && (
        <p key="empty" >
          {"No Records Found"}
        </p>
      )}
    </>

  );
}

export default Home
