import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance } from "axios";
import { msalInstance } from "..";


export interface IApiRequestHandler {
    getHostName(): string
    getOrDelete(
        url: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse>

    updateMethod(
        url: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse>
}

export const getSilentToken = (config: any) => {

    let request = {
        ...config,
        account: msalInstance.getAllAccounts()[0],
    };

    return new Promise(function (resolve, reject) {
        msalInstance.acquireTokenSilent(request)
            .then((tokenResponse) => {
                resolve(tokenResponse.accessToken);
            });
    })
}

export default class ApiRequestHandler implements IApiRequestHandler {
    private handler: AxiosInstance
    private apiHostName: string


    protected createAxiosInstance(): AxiosInstance {

        return axios.create({
            headers: {
                Accept: "application/json;odata=verbose",
                'Content-Type': "application/json; charset=UTF-8",
                'X-app-location': window.location.origin + window.location.pathname
            }
        })
    }

    constructor(apiConfig: any) {
        this.handler = this.createAxiosInstance();

        /**
        * Inject Authorization Request Header for all requests.
        */
        this.handler.interceptors.request.use(
            async request => {
                //request.params = {timestamp: Date.now()};
                let token = await getSilentToken(apiConfig);
                request.headers['Authorization'] = "Bearer " + token;
                return request
            },
            error => {
                return Promise.reject(error);
            }
        )
        this.apiHostName = apiConfig.hostname;
    }
    getHostName(): string {
        return this.apiHostName;
    }

    async getOrDelete(url: string, params: AxiosRequestConfig): Promise<AxiosResponse> {
        let method = params.method
        if (method === undefined) {
            method = "GET"
        }
        switch (method.toUpperCase()) {
            case "DELETE": {
                return this.handler.delete(url, params.headers)
                    .then((res: any) => {
                        return res
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            case "GET": {
                return this.handler.get(url, params.headers)
                    .then((res: any) => {
                        return res
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            default: {
                throw new Error('Invalid Method Type: Must use Get or Delete')
            }
        }
    }

    async updateMethod(url: string, params: AxiosRequestConfig): Promise<AxiosResponse<any>> {
        let method = params.method
        if (method === undefined) {
            method = "POST"
        }
        switch (method.toUpperCase()) {
            case "PATCH": {
                return this.handler.patch(url, params.data, params.headers)
                    .then((res: any) => {
                        return res
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            case "PUT": {
                return this.handler.put(url, params.data, params.headers)
                    .then((res: any) => {
                        return res
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            case "POST": {
                return this.handler.post(url, params.data, params.headers)
                    .then((res: any) => {
                        return res
                    })
                    .catch((error: AxiosError) => {
                        console.log(error.response);
                        return error.response;
                    });
            }
            default: {
                throw new Error('Invalid Method Type: Must use POST, PUT, or PATCH')
            }
        }
    }
}