import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { PublicClientApplication } from '@azure/msal-browser'
import { MsalProvider } from '@azure/msal-react'
import { msalConfig } from './authConfig'
import {BrowserRouter as Router } from 'react-router-dom'


export const msalInstance = new PublicClientApplication(msalConfig);

ReactDOM.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <Router>
                <App/>
            </Router>
        </MsalProvider>
    </React.StrictMode>
    , document.getElementById('root'))
