import React from 'react'
import './App.css'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react'
import Login from './pages/Login/Login'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from './pages/home'

function App() {
  return (
    <>
      <AuthenticatedTemplate>
          <Routes>
            <Route path="/" element={<Home/>}/>
          </Routes>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
          <Routes>
            <Route path="*" element={<Login/>}/>
          </Routes>
      </UnauthenticatedTemplate>
    </>
  )
}

export default App
