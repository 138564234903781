export const msalConfig = {
    auth: {
        clientId: 'ef364ce3-933b-4a08-b5c7-6039fc2372c5',
        authority: "https://login.microsoftonline.com/duvalschoolsorg.onmicrosoft.com",
        redirectUri: window.location.origin
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    }
};