import React from 'react'
import { HomeRoom } from "../../img/Icons";
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';
import { PrimaryButton } from '@fluentui/react';


const NoRecords = ({ studentCount }) => {

    const hasStudents = () => {
        if (studentCount > 0) {
            return true;
        }
        return false;
    }

    return (
        <>
            <Grid container className={"loginGrid"}>
                <div className={"loginGridDiv"}>
                    <div className={"headerTitle"}>
                        <HomeRoom width="100%" height="100%" />
                    </div>
                    <div style={{ textAlign: "center" }}>
                        {hasStudents() && (
                            <div style={{ width: "60%", margin: "auto" }}>
                                <p style={{ textAlign: "center" }}>Your student forms have already been submitted.</p>
                                <p style={{ textAlign: "center" }}>Please close this browser window.</p>
                            </div>
                        )}
                        {!hasStudents() && (
                            <div style={{ width: "60%", margin: "auto" }} >
                                <p style={{ textAlign: "center" }}>There are no students associated with your account. For more information on how to add students to your account, please click the link below.</p>
                                <Link to='https://dcps.duvalschools.org/enroll'>
                                    <PrimaryButton type="button" text={"Click Here"}/>
                                </Link>
                            </div>
                        )}

                        <br />
                    </div>
                </div>
            </Grid>
        </>
    );
}

export default NoRecords
