import { AxiosResponse } from "axios";
import { IApiRequestHandler } from "../utils/APIRequestHandler";

export interface IProfileService {
    getStudentInfo(): Promise<AxiosResponse>
    getECFForm(): Promise<AxiosResponse>
    postECFForm(formData: any): Promise<AxiosResponse>
}

export default class ProfileService implements IProfileService {
    private profileApiClient: IApiRequestHandler

    constructor(apiClient: IApiRequestHandler) {
        this.profileApiClient = apiClient;
    }
    async getECFForm(): Promise<AxiosResponse<any>> {
        let upn = "P-09B6DD214381A903D5@public.duvalschools.org"
        let url = this.profileApiClient.getHostName() + "/api/forms/ecf/" //?upn=" + encodeURI(upn);
        return await this.profileApiClient.getOrDelete(url, {});
    }
    async postECFForm(formData: any): Promise<AxiosResponse<any>> {
        let formInfo = JSON.stringify(formData);
        let url = this.profileApiClient.getHostName() + "/api/forms/ecf/";
        return await this.profileApiClient.updateMethod(url, { data: formInfo });
    }


    async getStudentInfo(): Promise<AxiosResponse> {
        let url = this.profileApiClient.getHostName() + "/api/profile/mystudentinfo";
        return await this.profileApiClient.getOrDelete(url, {});
    }
}
