export const setToLS = (key: any, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLS = (key: any) => {
  const value = window.localStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};

export const setToSS = (key: any, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSS = (key: any) => {
  const value = window.sessionStorage.getItem(key);

  if (value) {
    return JSON.parse(value);
  }
};

export function removeLSItems(regEx: any) {
  Object.keys(localStorage).forEach((key) => {
    if (regEx.test(key)) {
      localStorage.removeItem(key);
    }
  });
}

export function removeSSItems(regEx: any) {
  Object.keys(sessionStorage).forEach((key) => {
    if (regEx.test(key)) {
      sessionStorage.removeItem(key);
    }
  });
}
